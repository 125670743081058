<template>
    <v-dialog
        persistent
        v-model="resetPasswordDialog"
        transition="dialog-bottom-transition"
        overlay=false
        scrollable
        width="500px"
        min-width="500px"
        max-width="600px"
    >
        <v-card>
            <v-card-title class="success white--text">
                <span>{{ $t('message.resetPasswordFor') + ': ' + contactName }}</span>
            </v-card-title>
            <v-card-text>
                <v-overlay
                    :value="loading.get"
                    absolute
                    opacity="0.15"
                >
                    <v-row>
                        <v-col class="text-center">
                            <v-progress-circular
                                color="primary"
                                indeterminate
                                size="40"
                                width="6"
                            ></v-progress-circular>
                        </v-col>
                    </v-row>
                </v-overlay>
                <v-form lazy-validation ref="resetPasswordForm" v-model="validForm">
                    <v-layout row pt-3 v-if="false">
                        <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.currentPassword') }}</v-flex>
                        <v-flex xs7 lg7 md6 py-1>{{ currentPassword }}</v-flex>
                    </v-layout>
                    <v-layout row pt-3>
                        <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.newPassword') }}</v-flex>
                        <v-flex xs7 lg7 md6 py-1>
                            <v-text-field
                                :placeholder="$t('message.minimumLength') + ': 8 characters'"
                                :rules="[rules.characters,rules.empty]"
                                :value="newPassword"
                                autocomplete="new-password"
                                background-color="grey lighten-3"
                                class="force-text-left"
                                clearable
                                dense
                                hide-details="auto"
                                solo
                                type="password"
                                @change="newPassword = $event"
                            ></v-text-field>
                            <v-btn small @click="generatePassword()" class="mt-3 px-2 indigo lighten-2 white--text">{{ $t('message.generatePassword') }}</v-btn>
                        </v-flex>
                    </v-layout>
                </v-form>
            </v-card-text>
            <v-card-actions class="pt-0">
                <v-spacer></v-spacer>
                <v-btn color="default" small @click="resetPasswordDialog = false" >{{$t('message.dismiss')}}</v-btn>
                <v-btn
                    :loading="loading.reset"
                    color="info"
                    small
                    @click="resetPassword()"
                >{{ $t('message.proceed') }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import srs from 'secure-random-string'
import {api} from "Api";

export default {
    name: "AppicAccessResetPassword",
    props: ['customerId','contactEmail','contactName','dialog','dialogClosed','resetDone'],
    data() {
        return {
            currentPassword: null,
            loading: {
                get: false,
                reset: false
            },
            minPasswordLength: 8,
            newPassword: null,
            reset_password_dialog: false,
            rules: {
                characters: v => (v && /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/.test(v)) || this.$t('message.buyerAppicPasswordRule'),
                empty: v => !!v || this.$t('message.required')
            },
            validForm: true
        }
    },
    computed: {
        resetPasswordDialog: {
            get() {
                return this.reset_password_dialog;
            },
            set(value){
                this.reset_password_dialog = value
                if(value == false) {
                    this.$emit('dialog-closed')
                }
            }
        }
    },
    methods: {
        generatePassword() {
            this.newPassword = srs({length: 8, alphanumeric: true})
        },
        getAppicPasswordToResetByEmail() {
            return new Promise((resolve, reject) => {
                api
                    .get('/contacts/' + this.contactEmail + '/appic-password' )
                    .then(response => {
                        resolve(response.data.data[0])
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },
        resetPassword() {
            if(this.$refs.resetPasswordForm.validate()){
                let promise = new Promise ((resolve, reject) => {
                    api
                        .put("/contacts/" + this.contactEmail + "/appic-password", {
                            password: this.newPassword
                        })
                        .then(response => {
                            if (response.data.status == 'success') {
                                resolve('done')
                            } else {
                                resolve(response.data.message)
                            }
                        })
                        .catch(error => {
                            reject(error);
                        });
                })
                this.loading.reset = true
                promise
                    .then(response => {
                        if(response == 'done'){
                            this.$toast.success(this.$t('message.successes.passwordReset'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            this.loading.reset = false
                            this.$emit('password-reset')
                            this.resetPasswordDialog = false
                        } else {
                            this.$toast.error(response,
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                            this.$emit('password-not-reset')
                            this.loading.reset = false
                        }
                    })
                    .catch(() => {
                        this.$toast.error(this.$t('message.errors.passwordNotReset'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                        this.$emit('password-not-reset')
                        this.loading.reset = false
                    })
            }
        }
    },
    watch: {
        dialog(value) {
            if(value == true){
                this.loading.get = true
                this.getAppicPasswordToResetByEmail()
                    .then((data) => {
                        this.currentPassword = data.Contact.appic_password
                        this.newPassword = null
                        this.loading.get = false
                    })
                    .catch(() => {
                        this.loading.get = false
                    })
            } else {
                this.newPassword = null
                this.currentPassword = null
            }
            this.reset_password_dialog = value
        }
    }
}
</script>

<style scoped>

</style>